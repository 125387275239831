<template>
	<section>
		<b-row class="mb-2">
		    <b-col lg="12">
				<Breadcrumb :labels="labels" />		
		    </b-col>		    
		</b-row>		
		<b-row>
		    <b-col md="12">
		    	<div class="relative">
			        <b-tabs card class="caption-add table-custom">                       
			            <b-tab title="Basic Info" active>
			            	<validation-observer
						        ref="form_ref"
						        #default="{invalid}" >
				                <b-form @submit.prevent class="profile-frm hasshadow" style="border-radius: 5px; margin-top: 10px">
				                	<b-row class="align-center">
							            <b-col lg-12>
							            	<div class="text-right mb-2">		
							                    <template v-if="detail.status == 2 || detail.status == 0">
							            			<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled style="margin-right: 10px">
								                        Schedule
								                    </b-button>
								                    <b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled style="margin-right: 10px">
								                        Publish
								                    </b-button>
							            		</template>

							                    <template v-if="detail.status == 1">
							                    	<b-button type="button" variant="basic" class="btn-df size-18 black btn-disabled-gray" disabled style="margin-right: 10px ">
								                    	Unpublish
								                    </b-button>
							                    </template>	

							                    <template v-if="detail.status == 3">
							                    	<b-button type="button" variant="danger" class="btn-df size-18 black btn-disabled-gray" disabled style="margin-right: 10px;>
								                        Unschedule
								                    </b-button>
							                    	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled style="margin-right: 10px ">
								                        Reschedule
								                    </b-button>
							                    </template>
							                    <template v-if="userData">
							                    	<b-button type="button" variant="primary" class="btn-df size-18 black" @click="toEdit(detail.id)">
								                        Edit
								                    </b-button>
							                    </template>
							                    <template v-else>
							                    	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>Edit</b-button>
							                    </template>
							                </div>
							            </b-col>
							        </b-row>				                
					                <div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>
					                <div class="frm-basic size-16 d-block">              
				                      	<b-row style="width: 100%; margin: 0;"> 
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="AAID" label-cols-lg="4" class="star-required input-group-label">
				                                    <b-form-input
				                                    	v-model="detail.id"
				                                      	placeholder="Useful Info (P) ID"
				                                      	disabled
				                                    ></b-form-input>
					                            </b-form-group>
				                          	</b-col> 	
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Published Date" label-cols-lg="4" class="star-required">
				                                     <flat-pickr
					                                      	class="form-control"   
					                                      	v-model="firstPublishDate_Schedule"
					                                      	:config="{ altInput: true,altFormat: 'd/m/y', dateFormat: 'Y-m-d' }"
					                                      	placeholder="dd/mm/yy"
					                                      	disabled
					                                    />
					                            </b-form-group>
				                          	</b-col> 			                          		
				                          	<b-col lg="6" class="px-0">
				                          	
				                          		<b-form-group label="Image Banner (1600 x 900)" label-cols-lg="4" class="uploadImage pl-1600-lg-10">
				                          			
				                          			<div class="upload-show-name container-box" v-if="detail.image" style="flex-direction: column">
				                          				<div class="close-upload">
				                          					<feather-icon
																icon="XCircleIcon"
																size="24"
																color="#DD3E47"
															/>
				                          				</div>
				                          				<p>
				                          					<feather-icon
																icon="ImageIcon"
																size="30"
															/>
				                          				</p>
				                          				<template v-if="detail.image.name">{{ detail.image.name }}</template>
				                          				<template v-else>
				                          					<p style="max-width: 90%; word-break: break-all; text-align: center ">{{ detail.image ? detail.image : '' | splitStr }}</p>
				                          				</template>
					                          		</div>                   		
				                          			<div v-else class="bg container-box" :style="{ backgroundImage: `url(${require('@/assets/images/itees/admin/Upload-image.png')})` }">
							                            <b-form-file 
							                            	v-model="detail.image" 
							                            	plain 
							                            	accept=".jpg, .png" 
							                            	ref="fileImage"
															class="cursor-pointer"
															disabled
							                            >
					                                    </b-form-file>		                 
					                                </div>
					                               
				                                </b-form-group>
				                          	</b-col> 	 	
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Status" label-cols-lg="4" class="star-required">	
					                                <v-select
					                                    label="title"
					                                    class="disabled-field"
					                                    :options="selectStatus"
					                                    v-model="statusDetailText" 
					                                    placeholder="Select status"
					                                    disabled
				                                    />
					                            </b-form-group>
				                          	</b-col> 
				                          	<b-col lg="12" class="px-0">             
				                              	<b-form-group label="Title" label-cols-lg="2" class="star-required input-group-label">
				                              		<validation-provider
					                                  #default="{ errors }"
					                                  name="Title"
					                                  vid="title"
					                                  rules="required"                
					                                >
					                                    <b-form-input
					                                    	v-model="detail.title" 
					                                      	placeholder="Name"
					                                      	:formatter="length1000"
															disabled
					                                    ></b-form-input>    
					                                 	<small class="text-danger">{{ errors[0] }}</small>
                                					</validation-provider>              
				                              	</b-form-group>
				                          	</b-col>
				                          	<b-col lg="12" class="px-0">             
				                              	<b-form-group label="Description" label-cols-lg="2" class="star-required"> 
				                              		<validation-provider
					                                  #default="{ errors }"
					                                  name="Description"
					                                  vid="description"
					                                  rules="required"               
					                                >                           
					                                    <b-form-textarea
					                                    	v-model="detail.description"
					                                      	placeholder="Description"
					                                      	rows="5"
															disabled
					                                      	:formatter="length1000"
					                                    ></b-form-textarea>
					                                    <small class="text-danger">{{ errors[0] }}</small>
				                                    </validation-provider>       
				                              	</b-form-group>
				                          	</b-col>
				                      	</b-row>
					                </div>
					            </b-form> 
					        </validation-observer>
			            </b-tab>                           
			        </b-tabs>    
			        <div class="table-custom">
			        	<b-card class="px-table-0 document-table w-50-percent-cl-3 w-17-percent-cl-2 w-17-percent-cl-2">
		            		<div class="text-right mr-2 mb-2">
			                    <b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
			                        Add Document
			                    </b-button>					                    
			                </div>
			                <b-table small :fields="fields" :items="files" tbody-tr-class="size-14 table-row" show-empty @head-clicked="sortClicked">
			                	<template #head()="data">
						    		<span class="d-block text-center">{{ data.label }}</span>
						    	</template>
						    	<template #head(id)="data">
									<span>{{ data.label }}</span>
									<span :class="['cursor-pointer iconsort', {sort: data.field.key == sort.field && sort.type == 'desc' }]" style="position: relative; left: 20px;">
							        	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
							        </span>
								</template>	
								<template #head(attach_file_title)="data">
									<span>{{ data.label }}</span>
								</template>		
								<template #cell(id)="data">					
									<span v-if="data.field.key == sort.field && sort.type == 'desc'">
										{{ totalDocument_2 - data.index }}
									</span>
									<span v-else>
										{{ data.index + 1 }}
									</span>
								</template>	
								<template #cell(updated_at)="data">
									<span class="d-block text-center">{{ data.item.updated_at | formatDatebyMoment("YYYY-MM-DD", "DD/MM/YY") }}</span>
								</template>				
								<template #cell(attachable_type)="data">
									<span class="d-block text-center">{{ data.item.attachable_type }}</span>
								</template>	
								<template #cell(attach_file_title)="data">
									<span class="maxTwoRow">{{ data.item.attach_file_title }}</span>
								</template>			
								<template #cell(attach_content_type)="data">
									<span class="text-center maxTwoRow mx-auto">{{ data.item.attach_content_type ? data.item.attach_content_type : '-' }}</span>
								</template>				
								<template #cell(action)="data">
									<div class="d-block text-center">
										<span class="px-05 cursor-pointer" @click.stop="downloadDocument(data.item.attach_file_name, data.item.attach_file_title)">
											<feather-icon
									            icon="DownloadIcon"
									            size="22"
									          />
										</span>
										<span class="px-05">
											<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z" fill="#b1b1b1"/>
											</svg>
										</span>
										<span class="px-05">
											<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#b1b1b1"/>
											</svg>
										</span>
									</div>
								</template>	
			                	<template #empty="scope">
							      	<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
							    </template>
			                </b-table>
		            	</b-card>	
			        </div> 
			    </div>
			</b-col>
		</b-row>
	</section>
</template>
<script>

	import Breadcrumb from '@/views/itees/admin-dashboard/partial/Breadcrumb';
	import flatPickr from 'vue-flatpickr-component'

	import Datepicker from 'vuejs-datepicker';

    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
	import globalAdmin from '../../model/globalAdmin'
    const moment = require('moment');

	export default {
		mixins: [globalAdmin],
	  	components: {
	  		Breadcrumb,
	  		flatPickr,
	  		Datepicker,
          	vSelect,
          	ValidationProvider,
          	ValidationObserver,
	  	},
	  	data() {
		    return {
		    	userData: JSON.parse(localStorage.getItem('userData')),
				labels: { "administrations_userfulInfo_detail": "" },
				form: {date: null},			  
			    selectStatus: [
			    	{ title: 'Unpublished', value: '0' },
			    	{ title: 'Published', value: '1' },
			    	{ title: 'Draft', value: '2' },
			    	{ title: 'Schedule', value: '3' },
			    	{ title: 'Unschedule', value: '4' },
			    ],			   
			    date_schedule: null,
			    time_schedule: null,
				permission: [],
			    detail: [],
			    statusDetailText: null,
			    fields: [
			    	{key: 'id', label: 'No.'},
			    	{key: 'attach_file_title', label: 'Document Name'},
			    	{key: 'attach_content_type', label: 'Remarks'},
			    	{key: 'updated_at', label: 'Uploaded Date'},
			    	{key: 'action', label: 'Actions'},
			    ],
			    files: [],
			    totalDocument_2: 0,
			    sort: {
		      		"field": "id",
		      		"type": "asc"
		      	},
		      	fieldsDocument: {
		      		name: null,
		      		file: null,
		      		remarks: null
		      	},
		      	attachable_type: null,
		      	fieldsDocumentShow: {
		      		name: null,
		      		file: null,
		      		remarks: null
		      	},
		      	formatTimeReschedule: null,
		      	firstPublishDate_Schedule: null,
		      	firstPublishTime_Schedule: null,
		      	disableField: 'disabled-field'
            }
		},		
		computed: {
          	minScheduleDate(){            
              	var d = new Date()
              	d.setDate(d.getDate())
              	var now = moment(d).format('YYYY-MM-DD')
              	return now
          	},
          	minScheduleDate_rechedule(){
          		var d = new Date()
              	d.setDate(d.getDate())
              	var now = moment(d).format('YYYY-MM-DD')
              	return now
          	},
          	getListTime() {
	            let times = [];
	            for(let i = 0; i < 24; i++){
	            	let moment1 = this.detail.published_date ? moment(this.detail.published_date) : moment()
	                let time1 = moment1.startOf('date').hours(i).minutes(0).seconds(0).milliseconds(0);
	                if(moment().isSameOrBefore(time1)) {
	                    times.push({
	                        time: time1.format('HH:mm'),
	                        value: time1.format('HH:mm')
	                    })
	                }
	                let time2 = moment1.startOf('date').hours(i).minutes(30).seconds(0).milliseconds(0);
	                if(moment().isSameOrBefore(time2)) {
	                    times.push({
	                        time: time2.format('HH:mm'),
	                        value: time2.format('HH:mm')
	                    })
	                }
	            }
	            return times;
	        },
	        getListTime_add(){
	        	let times = [];
	            for(let i = 0; i < 24; i++){
	            	let moment1 = this.date_schedule ? moment(this.date_schedule) : moment()
	                let time1 = moment1.startOf('date').hours(i).minutes(0).seconds(0).milliseconds(0);
	                if(moment().isSameOrBefore(time1)) {
	                    times.push({
	                        time: time1.format('HH:mm'),
	                        value: time1.format('HH:mm')
	                    })
	                }
	                let time2 = moment1.startOf('date').hours(i).minutes(30).seconds(0).milliseconds(0);
	                if(moment().isSameOrBefore(time2)) {
	                    times.push({
	                        time: time2.format('HH:mm'),
	                        value: time2.format('HH:mm')
	                    })
	                }
	            }
	            return times;
	        }
        },
        filters: {
        	splitStr(val) {
        		if(!val) return null
        		if(typeof val == 'string') return val.split("/")[val.split("/").length -1 ]
        	}
        },
		created(){
			this.permission = this.checkPermission(this.userData,"Strategic Plan");
			this.fetchItem()
			this.fetchDocumentInfo()			
		},
		methods: {
			fetchItem(){
				this.$store
				.dispatch('auth/fetchUserfulDetail', {
					id: this.$route.params.id	
				})
				.then(response => {
					this.detail = response.data.data
					this.labels['administrations_userfulInfo_detail'] = response.data.data.title
					document.title = response.data.data.title + ' - ITEES | Your Global Partner of Choice'

					if(this.detail.status == 0) this.statusDetailText = 'Unpublished'
					if(this.detail.status == 1) this.statusDetailText = 'Published'
					if(this.detail.status == 2) this.statusDetailText = 'Draft'
					if(this.detail.status == 3) this.statusDetailText = 'Schedule'
					if(this.detail.status == 4) this.statusDetailText = 'Unschedule'

					this.detail.publised_time = moment(this.detail.publised_time, 'HH:mm:ss').format('HH:mm');
					this.firstPublishDate_Schedule = this.detail.published_date
					this.firstPublishTime_Schedule = this.detail.publised_time
				})
				.catch((error) => {
					console.log(error)
				})
			},	

			// document
			fetchDocumentInfo(){
				this.$store
				.dispatch('auth/fetchDocumentInfo', {
					filter: {
					  	"list_search": [
						    {
						      "attachable_id": this.$route.params.id,
						      "attachable_type": "useful_info"
						    }
						]
					},
					sort: this.sort
				})
				.then(response => {
					this.files = response.data.data
					this.totalDocument_2 = response.data.data.length
					//console.log('files attachable_type: ', response.data.data.attachable_type)
				})
				.catch((error) => {
					console.log(error)
				})
			},
			sortClicked(key) {
				if(this.sort.field == key){
					if(this.sort.type == 'asc') this.sort.type = 'desc'
					else this.sort.type = 'asc'
				}
				else{					
					this.sort.field = key
					this.sort.type = 'desc'
				}
				this.fetchDocumentInfo(); 
			},
		    downloadDocument(path, title){
		    	const url = `${process.env.VUE_APP_SERVER_URL}/api/downLoad?url_file=${path}`
		  		axios({
		        	method: 'get',
		        	url: url,
		        	responseType: 'blob',
		      	})
		      	.then(response => {		        
		        	this.forceFileDownload(response, title)	           
		      	})
		      	.catch(() => console.log('error occured'))
		    },
		    forceFileDownload(response, namFile){
		      	const url = window.URL.createObjectURL(new Blob([response.data]))
		      	const link = document.createElement('a')
		      	link.href = url
		      	link.setAttribute('download', namFile)
		      	document.body.appendChild(link)
		      	link.click()
		    },
			length100(e){
				return String(e).substring(0,100)
			},
			length1000(e){
				return String(e).substring(0,1000)
			},
			toEdit(id){
				this.$router.push(`/admin/administrations/userful-info/edit/${id}`)
			}
		},
	};	

</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	@import "@/assets/scss/_ite_tab_table.scss";
	.document-table thead tr th:first-child{
		width: 150px
	}
</style>